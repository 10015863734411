import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { PropertiesPageContents } from "../templates/properties"

const PropertiesByTagPage = ({ data, pageContext }) => {
  const isLocation =
    pageContext.data.Parent &&
    pageContext.data.Parent[0] &&
    pageContext.data.Parent[0].data &&
    pageContext.data.Parent[0].data.Reference === "location"

  return (
    <Layout {...pageContext}>
      <SEO
        lang={pageContext.lang}
        path={pageContext.pageUrl}
        title={
          isLocation
            ? data.seoTitleTextLocation
              ? data.seoTitleTextLocation.replace(
                  "{tag}",
                  pageContext.data.Name
                )
              : ""
            : data.seoTitleTextOpportunity
            ? data.seoTitleTextOpportunity.replace(
                "{tag}",
                pageContext.data.Name
              )
            : ""
        }
        description={
          isLocation
            ? data.seoDescriptionTextLocation
              ? data.seoDescriptionTextLocation.replace(
                  "{tag}",
                  pageContext.data.Name
                )
              : ""
            : data.seoDescriptionTextOpportunity
            ? data.seoDescriptionTextOpportunity.replace(
                "{tag}",
                pageContext.data.Name
              )
            : ""
        }
        image={
          data.properties.edges.length &&
          data.properties.edges[0].node.data.FeaturedImage.childImageSharp
            ? data.properties.edges[0].node.data.FeaturedImage.childImageSharp
                .resize.src
            : null
        }
      />
      <PropertiesPageContents tag={pageContext} {...data} />
    </Layout>
  )
}

export default PropertiesByTagPage

export const pageQuery = graphql`
  query PropertiesByTag($id: String!, $lang: String!) {
    ...PropertiesPageTranslations
    ...PropertiesCtaSectionTranslations

    seoTitleTextLocation: getTranslation(
      lang: $lang
      key: "properties_location_tag_seo_title"
    )
    seoDescriptionTextLocation: getTranslation(
      lang: $lang
      key: "properties_location_tag_seo_description"
    )
    seoTitleTextOpportunity: getTranslation(
      lang: $lang
      key: "properties_opportunity_tag_seo_title"
    )
    seoDescriptionTextOpportunity: getTranslation(
      lang: $lang
      key: "properties_opportunity_tag_seo_description"
    )

    properties: allAirtable(
      filter: {
        table: { eq: "Properties" }
        data: { Public: { eq: true }, Tags: { elemMatch: { id: { eq: $id } } } }
      }
      sort: { fields: data___Featured, order: ASC }
    ) {
      edges {
        node {
          fields {
            slugTrans(lang: $lang)
            opportunityTrans(lang: $lang)
            locationTrans(lang: $lang)
          }
          data {
            Featured
            Location
            TitleTrans(lang: $lang)
            BannerTrans(lang: $lang)
            SurfaceTrans(lang: $lang)
            BedroomsTrans(lang: $lang)
            PriceTrans(lang: $lang)
            Tags {
              recordId
              data {
                NameTrans(lang: $lang)
              }
            }
            FeaturedImage {
              childImageSharp {
                resize(width: 1200, height: 750) {
                  src
                }
              }
            }
          }
        }
      }
    }
    menu: getHeaderMenu(lang: $lang) {
      slug
      label
      title
      submenu {
        reference
        name
        slug
        title
      }
    }
  }
`
