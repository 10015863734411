import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Property } from "../components/properties-section"
import CtaSection from "../components/cta-section"

const PropertiesPage = ({ data, pageContext }) => (
  <Layout {...pageContext}>
    <SEO
      lang={pageContext.lang}
      path={pageContext.pageUrl}
      title={data.seoTitleText}
      description={data.seoDescriptionText}
    />
    <PropertiesPageContents {...data} />
  </Layout>
)

export const PropertiesPageContents = ({
  tag = null,
  properties,
  menu,
  ...props
}) => {
  const tagReference =
    tag &&
    tag.data.Parent &&
    tag.data.Parent[0] &&
    tag.data.Parent[0].data &&
    tag.data.Parent[0].data.Reference
      ? tag.data.Parent[0].data.Reference
      : null

  return (
    <>
      <div id="header" className="for-properties">
        <div className="section-content">
          <div className="big-text">
            <h1 className="t-left f-large normal">
              {!tag
                ? props.propertiesTagAll
                  ? props.propertiesTagAll
                  : ""
                : `${
                    tagReference === "location"
                      ? props.propertiesTagTitleIn
                        ? props.propertiesTagTitleIn
                        : ""
                      : props.propertiesTagTitleFor
                      ? props.propertiesTagTitleFor
                      : ""
                  } ${tag.data.Name} ${
                    tagReference !== "location" &&
                    props.propertiesTagTitleForAfter
                      ? props.propertiesTagTitleForAfter
                      : ""
                  }`}
            </h1>
            <div className="separator-small"></div>
            <ul className="t-left f-normal normal list-inline">
              <li>
                {props.propertiesTagTitleIn ? props.propertiesTagTitleIn : ""}
              </li>
              {menu[2].submenu
                .filter(item => item.reference === "location")
                .map((item, i) => (
                  <li key={i}>
                    <Link
                      activeClassName="gray1"
                      to={item.slug}
                      title={item.title}
                      className="underline"
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
            </ul>
            <ul className="t-left f-normal normal list-inline">
              <li>
                {props.propertiesTagTitleFor ? props.propertiesTagTitleFor : ""}
              </li>
              {menu[2].submenu
                .filter(item => item.reference === "opportunity")
                .map((item, i) => (
                  <li key={i}>
                    <Link
                      activeClassName="gray1"
                      to={item.slug}
                      title={item.title}
                      className="underline"
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
      <div id="properties">
        <div className="section-content">
          <div className="row">
            {properties.edges
              .filter(({ node }) => !!node.data.Featured)
              .map(({ node }, i) => (
                <Property key={i} {...node} gutter={false} />
              ))}
            {properties.edges
              .filter(({ node }) => !node.data.Featured)
              .map(({ node }, i) => (
                <Property key={i} {...node} gutter={false} />
              ))}
          </div>
        </div>
      </div>
      <CtaSection
        title={props.ctaSectionTitle}
        link={props.ctaSectionCta}
        linkHref={props.ctaSectionCtaHref}
        linkTitle={props.ctaSectionCtaTitle}
      />
    </>
  )
}

export default PropertiesPage

export const pageQuery = graphql`
  fragment PropertiesPageTranslations on Query {
    propertiesTagTitleIn: getTranslation(
      lang: $lang
      key: "properties_tag_title_in"
    )
    propertiesTagTitleFor: getTranslation(
      lang: $lang
      key: "properties_tag_title_for"
    )
    propertiesTagTitleForAfter: getTranslation(
      lang: $lang
      key: "properties_tag_title_for_after"
    )
    propertiesTagAll: getTranslation(lang: $lang, key: "properties_tag_all")
  }

  fragment PropertiesCtaSectionTranslations on Query {
    ctaSectionTitle: getTranslation(
      lang: $lang
      key: "properties_cta_section_title"
    )
    ctaSectionCta: getTranslation(
      lang: $lang
      key: "properties_cta_section_cta"
    )
    ctaSectionCtaHref: getTranslation(
      lang: $lang
      key: "properties_cta_section_cta_href"
    )
    ctaSectionCtaTitle: getTranslation(
      lang: $lang
      key: "properties_cta_section_cta_title"
    )
  }

  query PropertiesTemplate($lang: String!) {
    seoTitleText: getTranslation(lang: $lang, key: "properties_seo_title")
    seoDescriptionText: getTranslation(
      lang: $lang
      key: "properties_seo_description"
    )

    ...PropertiesPageTranslations
    ...PropertiesCtaSectionTranslations

    properties: allAirtable(
      filter: { table: { eq: "Properties" }, data: { Public: { eq: true } } }
      sort: { fields: data___Featured, order: ASC }
    ) {
      edges {
        node {
          fields {
            slugTrans(lang: $lang)
            opportunityTrans(lang: $lang)
            locationTrans(lang: $lang)
          }
          data {
            Featured
            Location
            TitleTrans(lang: $lang)
            BannerTrans(lang: $lang)
            SurfaceTrans(lang: $lang)
            BedroomsTrans(lang: $lang)
            PriceTrans(lang: $lang)
            Tags {
              recordId
              data {
                NameTrans(lang: $lang)
              }
            }
            FeaturedImage {
              childImageSharp {
                resize(width: 1200, height: 750) {
                  src
                }
              }
            }
          }
        }
      }
    }
    menu: getHeaderMenu(lang: $lang) {
      slug
      label
      title
      submenu {
        reference
        name
        slug
        title
      }
    }
  }
`
